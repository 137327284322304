import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec, SelectSpec } from 'components/bel-au-html-table/component';
import { DashboardClientType, HealthState, MyHttpApi, Repository, RepositoryListInternalRequest, WarningReason } from 'utils/api';
import { Notify } from 'utils/notify';

interface UIClient {
  id: number;
  nickname: string;
  tuloposVersion?: string;
  targetBranch?: string;
  clientType: DashboardClientType;
  state?: HealthState;
  error?: string;
  warningReasons: WarningReason[];
}

@autoinject
export class AdminClientsPatchUpdateTool {
  private content: UIClient[] = [];
  private repoSearchCriteria: RepositoryListInternalRequest = { onlyStable: false, archived: false };
  private tuloPosTargetVersions = {};
  private fields: FieldSpec[] = [
    { header: "common.name", key: "nickname", type: "text", },
    { header: "client.state", key: "state", type: "text" },
    { header: "repository.branch", key: "tuloposVersion", type: "text" },
    { header: "client.targetBranch", key: "targetBranch", type: "text" },
    { header: "client.error", key: "error", type: "text" },
    { header: "common.type", key: "clientType", type: "text" },
  ];
  private selectedClientsForUpdate: SelectSpec = {};
  private emergencyUpdate = false;
  private showEmergencyUpdateModal = false;
  private emergencyUpdateRepositoryId?: number;
  private repositoryList: Repository[] = [];
  private tuloposVersions: { id: string, name: string; }[] = [];
  private targetBranch = "";

  constructor(private readonly api: MyHttpApi, private readonly i18n: I18N, private readonly router: Router, private readonly notify: Notify) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    this.emergencyUpdate = false;
    this.emergencyUpdateRepositoryId = undefined;
    let [chrList, repositoryList, tuloposVersions] = await Promise.all([
      this.api.clientClientHealthAndRepositories(),
      this.api.repositoryList(this.repoSearchCriteria),
      this.api.repositoryListBranches(),

    ]);
    this.repositoryList = repositoryList;
    this.content = chrList.map(chr => {
      let item: UIClient = {
        id: chr.client.id,
        clientType: chr.client.clientType,
        targetBranch: chr.client.targetBranch,
        nickname: chr.client.nickname,
        state: chr.health?.state,
        error: chr.health?.error,
        warningReasons: chr.health?.warningReasons || [],
      };
      if (chr.health?.branch && chr.health.abbreviatedSha) {
        item.tuloposVersion = chr.health.branch + "-" + chr.health.abbreviatedSha;
      }
      return item;

    });
    this.tuloPosTargetVersions = this.repositoryList.map(x => (x.branch + "-" + x.abbreviatedSha));
    this.tuloposVersions = tuloposVersions.map(v => {
      return { id: v, name: v };
    });
  }

  setTargetRepository(version: string) {
    const repository = this.repositoryList.find(r => r.branch + "-" + r.abbreviatedSha == version);
    this.emergencyUpdateRepositoryId = repository?.id;
  }

  async executeEmergencyUpdate() {
    this.showEmergencyUpdateModal = false;
    const clientIds = this.getClientIds();

    if (!this.emergencyUpdateRepositoryId) {
      this.notify.info("client.chooseVersion");
      return;
    }

    await this.api.clientClientEmergencyUpdate({
      emergencyUpdateRepositoryId: this.emergencyUpdateRepositoryId,
      clientIds
    });
    await this.refresh();
  }

  async enableUpdate() {
    const clientIds = this.getClientIds();

    if (!clientIds.length) {
      this.notify.info("common.chooseClient");
      return;
    }

    // * Emergeency update will be continued in modal
    if (this.emergencyUpdate) {
      if (!this.emergencyUpdateRepositoryId) {
        this.notify.info("client.chooseVersion");
        return;
      }

      this.showEmergencyUpdateModal = true;
      return;
    }

    let targetBranch: string|undefined = this.targetBranch;
    if (!this.targetBranch) {
      targetBranch = undefined;
    }

    await this.api.clientTargetBranch({
      targetBranch,
      clientIds
    });
    await this.refresh();
  }

  getClientIds(): number[] {
    const array: number[] = [];
    for (const [key, value] of Object.entries(this.selectedClientsForUpdate)) {
      if (value) {
        array.push(parseInt(key));
      }
    }
    return array;
  }

}
