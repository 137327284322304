import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ClientMakeRequest, MyHttpApi } from 'utils/api';
import { Notify } from 'utils/notify';

@autoinject
export class AdminClientsNew {
  client: ClientMakeRequest = {
    name: "",
    nickname: "",
    zip: "",
    city: "",
    canUpdateToCanary: false,
    onPremisesIpAddress: "192.168.100.50",
    updateJava: false,
    updatePostgresql: false,
    updateNginxConf: false,
    updateServiceAndSocket: false,
    javaMemory: 1000,
    psqlMemory: 1000,
  };

  constructor(private readonly router: Router, private readonly notify: Notify, private readonly api: MyHttpApi) {
  }

  async make() {
    if (!this.client) {
      return;
    }
    await this.api.clientMake(this.client);
    this.notify.info("client.created");
    this.router.navigateToRoute("admin/clients/list");
  }
}
